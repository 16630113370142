.app {
  padding-left:10px;
  padding-right: 10px;
}

.app-header {
  display: flex; 
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
  padding-top: 15px;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .app-header {
    flex-direction: column;
  }

  .divider {
    display: none;
  }
}

.divider {
  padding-left: 20px;
  padding-right: 20px; 
}

.divider div {
  border-left: #000 2px solid;
  height: 50px;
}

.logo {
  width: 100px;
}

.photo {
  width:100%;
}

.instruction {
  font-size: 12px;
  padding-bottom: 10px;
}
.photos-container {
  display: grid; 
  grid-template-columns: repeat(auto-fit, minmax(min-content, 500px));
  gap:15px;
  grid-auto-rows: auto;
}

.photo-container {
  
  cursor: pointer;
}

.downloading {
  display: flex;
  position: fixed;
  top:0;
  left:0;
  background-color: rgba(0,0,0, 0.8);
  height: 100%;
  width: 100%;
  color: white;
  font-size: 30px;
  align-items:  center;
  justify-content: center;
}

.center {
  text-align: center;
  display:flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: navy navy transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent #C1272D #C1272D;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
    
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
    